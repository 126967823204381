import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import News from "../views/News";
import Contact from "../views/Contact";
import Catalog from "../views/Catalog"
import Products from "../views/Products"
import Imprint from "../views/Imprint";
import PrivacyPolicy from "../views/PrivacyPolicy";
import SelectedLatestNewsDetail from "../components/SelectedLatestNewsDetail";

Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// } 
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/catalogs",
    name: "Catalogs",
    component: Catalog,
  },
  {
    path: "/about-us",
    name: "About",
    component: About,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/news/:id",
    name: "SelectedLatestNewsDetail",
    component: SelectedLatestNewsDetail,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
