<template>
   <div id="app">
      <div id="nav">
         <Header />
      </div>
      <router-view />
      <Footer />
   </div>
</template>
<script>
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

export default {
   name: "App",

   components: {
      Header,
      Footer,
   },
   // data() {
   //    return {
   //       view: {
   //          atTopOfPage: true,
   //       },
   //    };
   // },

   // a beforeMount call to add a listener to the window
   // beforeMount() {
   //    window.addEventListener("scroll", this.handleScroll);
   // },

   // methods: {
   //    // the function to call when the user scrolls, added as a method
   //    handleScroll() {
   //       // when the user scrolls, check the pageYOffset
   //       if (window.pageYOffset > 0) {
   //          // user is scrolled
   //          if (this.view.atTopOfPage) this.view.atTopOfPage = false;
   //       } else {
   //          // user is at top of page
   //          if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
   //       }
   //    },
   // },
};
</script>
<style lang="scss">
#app {
   font-family: "Roboto", Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   // text-align: center;
   // color: #2c3e50;
}

#nav {
   // padding: 30px;

   a {
      // font-weight: bold;
      // color: #2c3e50;

      &.router-link-exact-active {
         color: #7BbB02;
         font-weight: bold;
      }
   }
}
.scrolled {
   box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
   border-bottom: 0px;
}
</style>
