<template>
<section>
  <div class="container max-w-4xl px-4 py-24 mx-auto font-sans">
      <div class="-my-8 divide-y-2 divide-gray-100">
        <div class="flex flex-wrap py-8 md:flex-nowrap">
          <div class="md:flex-grow">
            <h2 class="mb-2 text-2xl font-bold text-gray-900 md:text-3xl md:leading-tight md:font-extrabold">Privacy Policy</h2>
            <p class="mb-5 text-base font-light text-center text-gray-800 sm:text-left sm:rtl:text-right md:text-md" v-if="privacy.Content">
              <VueShowdown :markdown="privacy.Content" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "PrivacyPolicy",
  data: () => ({
    privacy: "loading",
  }),
  async mounted() {
    try {
      const privacy = await axios.get(`${baseUrl}/privacy-policy`);
      this.privacy = privacy.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
