<template>
  <div class="bg-gray-100">
    <footer class="container px-8 py-8 mx-auto font-sans max-w-7xl sm:py-12">
        <div class="flex flex-wrap sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
          <div class="w-1/4 px-4 md:w-1/4 xl:w-1/6">
            <h5 class="mb-4 font-semibold tracking-wider text-gray-800 text-md">
              Sitemap
            </h5>
            <ul class="list-none footer-links">
              <li v-for="sitemap in sitemaps" :key="sitemap.name" class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  :to="sitemap.route"
                  class="text-sm text-gray-600 transition-colors duration-300 border-b border-transparent border-solid hover:border-green-600 hover:text-primary-green"
                >
                  {{ sitemap.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div
            class="w-3/4 px-4 sm:w-2/3 xl:w-2/6 xl:mt-0 xl:ml-auto"
            v-for="item in footer"
            :key="item.id"
          >
            <h5 class="mb-4 font-semibold tracking-wider text-gray-800 text-md">
              Contact us
            </h5>
            <div>
              <h2 class="text-xs font-semibold tracking-widest text-gray-900">
                PHYSICAL ADDRESS
              </h2>
              <p class="mt-1 text-sm leading-relaxed text-gray-600">
                <VueShowdown :markdown="item.PhysicalAddress" />
              </p>
            </div>
            <div>
              <h2
                class="mt-4 text-xs font-semibold tracking-widest text-gray-900"
              >
                EMAIL
              </h2>
              <a
                :href="`mailto:${item.Email}`"
                class="text-sm leading-relaxed text-primary-blue"
              >
                <VueShowdown :markdown="item.Email" />
              </a>
              <h2
                class="mt-4 text-xs font-semibold tracking-widest text-gray-900"
              >
                PHONE
              </h2>
              <p class="text-sm leading-relaxed text-gray-600">
                <VueShowdown :markdown="item.PhoneNumber" />
              </p>
            </div>
          </div>
          <div class="w-2/4 px-4 mt-8 sm:w-1/3 xl:w-1/6 xl:mt-0 xl:ml-auto">
            <h5 class="mb-4 font-semibold tracking-wider text-gray-900 text-md">
              Terms and Policies
            </h5>
            <ul class="list-none footer-links">
              <li class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  to="/imprint"
                  class="text-sm font-medium text-gray-600 transition-colors duration-300 border-b border-transparent border-solid hover:border-green-600 hover:text-primary-green"
                >
                  Imprint
                </router-link>
              </li>
              <li class="mb-2">
                <router-link
                  @click.native="scrollToTop"
                  to="/privacy-policy"
                  class="text-sm font-medium text-gray-600 transition-colors duration-300 border-b border-transparent border-solid hover:border-green-600 hover:text-primary-green"
                >
                  Privacy policy
                </router-link>
              </li>
            </ul>
          </div>
          <div
            class="w-2/4 px-4 mt-8 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto"
          >
            <h5
              class="mb-4 font-semibold tracking-wider text-gray-900 text-md sm:text-center xl:text-left"
            >
              Stay connected
            </h5>
            <div
              class="flex flex-wrap space-x-3 sm:justify-center xl:justify-start"
            >
              <a
                v-for="item in social"
                :key="item.id"
                :href="item.Url"
                target="_blank"
                rel="noreferrer noopener"
                class="px-1 py-1 rounded-full outline-none hover:bg-white focus:outline-none"
              >
                <img :src="getBaseUrl()+item.Image.url" alt="socials" class="w-6 h-6" />
              </a>
            </div>
          </div>
        </div>
        <div
          class="pt-6 mt-6 border-t sm:flex sm:flex-wrap sm:-mx-4 sm:mt-12 sm:pt-12"
        >
          <div class="w-full px-4 text-sm text-center text-gray-600">
            <p>&copy;{{ new Date().getFullYear() }} <span class="font-bold text-primary-green">Green Globe</span> <span class="text-primary-blue">Plastic Industries LLC </span></p>
          </div>
        </div>
      
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "Footer",
  data: () => ({
    footer: [],
    social: [],
    sitemaps: [
      {
        name: "Home",
        route: "/",
      },
      {
        name: "Products",
        route: "/products",
      },
      {
        name: "Catalog",
        route: "/catalogs",
      },
      {
        name: "News",
        route: "/news",
      },

      {
        name: "Contact us",
        route: "/contact-us",
      },
      {
        name: "About us",
        route: "/about-us",
      },
    ],
  }),
  methods: {
    getBaseUrl(){
      return baseUrl
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
  async mounted() {
    try {
      const footer = await axios.get(`${baseUrl}/footers`);
      this.footer = footer.data;
      const social = await axios.get(`${baseUrl}/socials`);
      this.social = social.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
