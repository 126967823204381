<template>
  <div>
    <section
      class="container px-8 py-10 mx-auto font-sans text-gray-600 max-w-7xl"
    >
      <div
        class="relative flex items-end justify-start overflow-hidden rounded-sm h-96"
      >
        <iframe
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=Al%20Saja%E2%80%99a%20Industrial%20Oasis%20Sharjah,%20UAE+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ><a href="https://www.gps.ie/golf-gps/">golf gps</a></iframe
        >
      </div>
      <div class="flex flex-wrap mx-auto mt-20">
        <div
          class="flex flex-col w-full mb-12 md:mt-24 lg:w-1/2 lg:mb-0 lg:items-center"
        >
          <h1
            class="mb-4 text-3xl font-semibold tracking-tight text-center text-black md:leading-tight sm:w-4/5 sm:rtl:text-right md:text-4xl"
          >
            Our office is 24 Hours Open
          </h1>
          <div class="flex items-center justify-center space-x-4 sm:w-4/5">
            <svg class="w-14 h-14" viewBox="0 0 20 20" fill="#7BbB02">
              <path
                d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
              />
            </svg>
            <div>
              <!-- <span>+971 6 526 9253</span><br /> -->
              <span>+971 6881 2592</span><br />
              <span>+971 50 954 0059</span>
            </div>
          </div>
        </div>
        <div class="flex flex-col w-full lg:pl-10 lg:w-2/5 sm:w-4/5 sm:mx-auto">
          <h2 class="mb-1 text-2xl font-semibold tracking-tight text-black">
            Contact us
          </h2>
          <p class="mb-3 font-normal text-gray-600">Leave us a message here</p>
          <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit">
              <div class="relative mb-4">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="name"
                    v-model="name"
                    name="name"
                    placeholder="Name"
                    class="block w-full px-3 py-1 mt-2 text-base leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-white border border-gray-400 rounded-sm outline-none focus:border-primary-blue focus:ring-1 focus:ring-primary-blue"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="relative mb-4">
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    name="email"
                    placeholder="Email"
                    class="block w-full px-3 py-1 mt-2 text-base leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-white border border-gray-400 rounded-sm outline-none focus:border-primary-blue focus:ring-1 focus:ring-primary-blue"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="relative mb-4">
                <label
                  for="message"
                  class="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <ValidationProvider
                  name="Message"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <textarea
                    id="message"
                    v-model="message"
                    name="message"
                    placeholder="Message"
                    class="block w-full h-32 px-3 py-1 mt-2 text-base leading-6 text-gray-700 transition-colors duration-200 ease-in-out bg-white border border-gray-400 rounded-sm outline-none resize-none focus:border-primary-blue focus:ring-1 focus:ring-primary-blue"
                  ></textarea>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <button
                loading="true"
                type="submit"
                value="send"
                class="w-full py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded-sm bg-primary-blue focus:outline-none hover:bg-blue-800"
              >
                Send Message
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import emailjs from "emailjs-com";
import { baseUrl } from "../Api";

export default {
  name: "Contact",
  data: () => ({
    successMessage: false,
    items: [],
    valid: true,
    name: "",
    email: "",
    message: "",
    error: false,
    loading: false,
    snackbar: false,
    snackbar_message: "",
  }),
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/contacts`);
      this.items = response.data;
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
    showToast() {
      this.$toast.success("Message sent sucessfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          emailjs.sendForm(
            "service_zlnw8io",
            "template_mtsg036",
            e.target,
            "user_pHlXRSBk1ewiAuGzuc7jE"
          );
          this.$nextTick(() => {
            this.$refs.form.reset();
            this.name = "";
            this.email = "";
            this.message = "";
            this.showToast();
          });
        } catch (error) {
          console.log("Error");
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style></style>
