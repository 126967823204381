<template>
  <div>
    <section
      class="container px-8 mx-auto my-24 font-sans text-gray-600 max-w-7xl"
    >
      <h1 class="mb-20 text-3xl font-bold text-center text-gray-900">
        Latest News
      </h1>
      <div
        class="grid grid-cols-1 gap-12 place-items-start md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
      >
        <div v-for="news in latestNews" :key="news.id">
          <img
            :src="getBaseUrl() + news.newsImage.url"
            :alt="news.newsTitle"
            class="object-cover w-full h-56 mb-5 bg-center rounded"
          />
          <h2 class="mb-2 text-lg font-semibold text-gray-900">
            {{ news.newsTitle }}
          </h2>
          <p class="mb-3 text-sm font-normal text-gray-600">
            <VueShowdown :markdown="news.newsContent" />
          </p>
          <p v-if="news.newsSource" class="mt-2">
            <span class="italic font-medium">Source link: </span>
            <a
              :href="news.newsSource"
              target="_blank"
              class="border-b border-transparent hover:text-primary-orange hover:border-primary-orange"
              >{{ news.newsSource }}</a
            >
          </p>
          <router-link
            :to="{
              name: 'SelectedLatestNewsDetail',
              params: { news: news, id: news.id },
            }"
            @click.native="scrollToTop"
            class="inline-flex items-center mt-2 transition-colors duration-300 border-b border-transparent border-solid cursor-pointer hover:text-blue-600 text-primary-blue"
          >
            Read More
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "News",
  data() {
    return {
      showMore: true,
      latestNews: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  methods: {
    getBaseUrl(){
      return baseUrl
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/latest-news`);
      let sorted = response.data.sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
      this.latestNews = sorted.splice(0, 3);

      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped></style>
