<template>
  <section>
    <slider
      animation="fade"
      :autoplay="true"
      :duration="5000"
      :speed="1000"
      :stopOnHover="true"
      :height="500 + 'px'"
    >
      <slider-item
        v-for="item in items"
        :key="item.id"
        :style="{
          backgroundImage: 'url('+ getBaseUrl() + item.Images.url + ')',
          'object fit': 'cover',
          'background-repeat': 'no-repeat',
          'z-index': '2',
          'background-size': 'cover',
          'background-position': 'center',
          position: 'relative',
        }"
      >
        <div
          class="left-0 flex flex-col items-center justify-center h-full font-sans text-gray-200 bg-black md:justify-end bg-opacity-40 "
        >
        <div class="p-4 mx-8 text-center md:pb-32 md:pl-32">

          <p class="text-2xl font-bold text-gray-200 md:text-4xl">{{ item.Title }}</p>
          <p>{{ item.Content }}</p>
        </div>
        </div>
      </slider-item>
      <!-- <div slot="loading">loading ...</div> -->
    </slider>
  </section>
</template>

<script>
import axios from "axios";
import { Slider, SliderItem } from "vue-easy-slider";
import { baseUrl } from "../Api";

export default {
  name: "Hero",
  components: { Slider, SliderItem },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getBaseUrl(){
      return baseUrl
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/slide-shows`);
      let sorted = response.data.sort((a,b) => a.sequence - b.sequence)
      this.items = sorted;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style lang="scss">
.slider-indicator-icon {
  display: inline-block;
  width: 15px !important;
  height: 15px !important;
  margin: 0 0.1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #fff;
}
.slider-indicator-active {
  background-color: #013879 !important;
}
.slider-icon {
  width: 20px !important;
  height: 20px !important;
  font-size: bold;
}
</style>
