<template>
  <div>
    <section class="container px-8 py-24 mx-auto font-sans text-gray-600 max-w-7xl">
      <div v-if="!loading">
        <h1
          class="mb-2 text-4xl font-bold text-center text-gray-900 md:text-5xl md:leading-tight md:font-extrabold"
        >
          Our Products
        </h1>
        <p
          class="max-w-4xl mx-auto mb-20 font-sans text-lg text-center text-gray-600 "
        >
          Our products range from polyester straps, polyester monofilament,
          plastic brushes to polyester rope. <br />
          Each product is made from 100% recycled polyethylene terephthalate
          (PET), making the products extremely durable, versatile, lightweight,
          strong, safe, and weather-resistant. Our products are available in set
          dimensions and colours and can also be customized based on customer
          requirements.
        </p>
        <div
          class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
          v-for="(product, index) in items"
          :key="product.id"
        >
          <div
            class="order-1"
            :class="index % 2 === 0 ? 'md:order-1' : 'md:order-2'"
          >
            <div class="flex flex-col justify-between">
              <h1
                class="text-2xl font-semibold tracking-tight text-center text-primary-green md:leading-tight sm:text-left md:text-2xl"
              >
                {{ product.Title }}
              </h1>
              <div
                class="w-20 h-0.5 mb-4 text-center rounded bg-primary-blue"
              ></div>
            </div>
            <p
              class="mb-5 text-base font-light text-center text-gray-800 sm:text-left sm:rtl:text-right md:text-md"
            >
              <VueShowdown :markdown="product.Content" />
            </p>
          </div>
          <div
            class="w-full h-full"
            :class="index % 2 === 0 ? 'order-2' : 'order-1'"
          >
            <img :alt="product.Title" :src="getBaseUrl() + product.Images.url" />
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Products",
  data() {
    return {
      items: [],
      more: false,
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  methods: {
    getBaseUrl(){
      return baseUrl
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    // showMore(content) {
    //     var temp = content.trim().split(/\s+/).length > 36 ? true : false;
    //     this.more = temp;
    //     return temp;

    // },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/products`);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
