var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('slider',{attrs:{"animation":"fade","autoplay":true,"duration":5000,"speed":1000,"stopOnHover":true,"height":500 + 'px'}},_vm._l((_vm.items),function(item){return _c('slider-item',{key:item.id,style:({
        backgroundImage: 'url('+ _vm.getBaseUrl() + item.Images.url + ')',
        'object fit': 'cover',
        'background-repeat': 'no-repeat',
        'z-index': '2',
        'background-size': 'cover',
        'background-position': 'center',
        position: 'relative',
      })},[_c('div',{staticClass:"left-0 flex flex-col items-center justify-center h-full font-sans text-gray-200 bg-black md:justify-end bg-opacity-40 "},[_c('div',{staticClass:"p-4 mx-8 text-center md:pb-32 md:pl-32"},[_c('p',{staticClass:"text-2xl font-bold text-gray-200 md:text-4xl"},[_vm._v(_vm._s(item.Title))]),_c('p',[_vm._v(_vm._s(item.Content))])])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }