<template>
  <div>
    <section class="container px-8 py-24 mx-auto text-gray-600 max-w-7xl">
      <div v-if="!loading">
          <div v-for="news in items" :key="news.id">
            <SingleNewsDisplay :news="news" :baseUrl="getBaseUrl()" />
          </div>
        
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import SingleNewsDisplay from "../components/SingleNewsDisplay.vue";

export default {
  name: "News",
  components: { SingleNewsDisplay },
  data() {
    return {
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
    };
  },
  methods:{
    getBaseUrl(){
      return baseUrl
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/latest-news`);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
