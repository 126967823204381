<template>
  <div class="px-8 mx-auto my-24 font-sans text-gray-600 max-w-7xl">
    <div
      class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
      v-for="item in items"
      :key="item.id"
    >
      <div class="order-1 md:order-2">
        <h1
          class="mb-3 text-4xl font-semibold tracking-tight text-center text-primary-blue md:leading-tight sm:text-left md:text-4xl"
        >
          Our Recycling process
        </h1>
      </div>
      <div class="order-2 w-full h-full md:order-1">
        <video
          controls
          muted
          :src="getBaseUrl() + item.Video.url"
          class="w-full border rounded-sm border-primary-blue"
        ></video>
      </div>
    </div>
    <!-- <div v-for="item in items" :key="item.id" class="">
      <video
        controls
        muted
        :src="getBaseUrl() + item.Video.url"
        class="w-full"
      ></video>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Video",
  data: () => ({
    items: [],
  }),
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/videos`);
      this.items = response.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
