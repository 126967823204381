<template>
  <section class="container px-8 py-24 mx-auto font-sans text-gray-600 max-w-7xl">
    <div v-if="!loading">
      <div
        class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 md:gap-x-24"
        v-for="(about, index) in items"
        :key="about.id"
      >
        <div
          class="order-1"
          :class="index % 2 === 0 ? 'md:order-1' : 'md:order-2'"
        >
          <div class="flex flex-col justify-between">
            <h1
              class="mb-3 text-2xl font-semibold tracking-tight text-center text-primary-green md:leading-tight sm:text-left md:text-2xl"
            >
              {{ about.aboutname }}
            </h1>
            <div
              class="w-20 h-0.5 mb-4 text-center rounded bg-primary-blue"
            ></div>
          </div>
          <p
            class="mb-5 text-base font-light text-center text-gray-800 sm:text-left sm:rtl:text-right md:text-md"
          >
            <VueShowdown :markdown="about.content" />
          </p>
        </div>
        <div
          class="w-full h-full"
          :class="index % 2 === 0 ? 'order-2' : 'order-1'"
        >
          <img :alt="about.aboutname" :src="getBaseUrl() + about.image.url" />
        </div>
      </div>
    </div>
    <div v-else>
      <img :src="loadingImage" class="w-64 m-auto" />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";
// import OurValues from "../components/OurValues.vue";
// import Principles from "../components/PrinciplesOfDevelopment.vue";
// import Vission from "../components/Vission.vue";
// import Mission from "../components/Mission.vue";

export default {
  name: "About",
  components: {
    //  OurValues,
    //  Principles,
    //  Vission,
    //  Mission,
  },
  data() {
    return {
      items: [],
      loadingImage: require("../assets/curve-Loading.gif"),
      loading: true,
      error: null,
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/abouts`);
      this.items = response.data;
      // console.log("this.items", this.items);
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>
<style scoped></style>
