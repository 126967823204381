<template>
   <div>
      <Hero></Hero>
      <Sections></Sections>
      <Video></Video>
      <Affiliate></Affiliate>
      <LatestNews></LatestNews>
   </div>
</template>

<script>
import Hero from "../components/Hero.vue";
import Video from "../components/Video.vue";
import Sections from "../components/Sections.vue";
import Affiliate from "../components/Affiliate.vue";
import LatestNews from "../components/LatestNews.vue";

// @ is an alias to /src

export default {
   name: "Home",
   components: { Hero, Video, Sections, Affiliate, LatestNews},
};
</script>
<style></style>
