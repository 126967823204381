<template>
  <section>
    <div class="container max-w-4xl px-4 py-24 mx-auto font-sans">
      <div class="-my-8 divide-y-2 divide-gray-100">
        <div class="flex flex-wrap py-8 md:flex-nowrap">
          <div class="md:flex-grow">
            <h2 class="mb-2 text-2xl font-bold text-gray-900 md:text-3xl md:leading-tight md:font-extrabold">Imprint</h2>
            <p class="mb-5 text-base font-light text-center text-gray-800 sm:text-left sm:rtl:text-right md:text-md" v-if="imprint.Content">
              <VueShowdown :markdown="imprint.Content" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Imprint",
  data() {
    return {
      imprint: "loading",
    };
  },
  async mounted() {
    try {
      const imprint = await axios.get(`${baseUrl}/imprint`);
      this.imprint = imprint.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
