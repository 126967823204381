<template>
  <div>
    <section class="px-8 mx-auto my-24 font-sans text-gray-600 max-w-7xl">
      <h1
        class="mb-20 text-3xl font-bold text-center text-primary-blue md:text-4xl md:leading-tight md:font-extrabold"
      >
        Affiliate Companies
      </h1>

      <div
        class="grid content-center grid-cols-1 gap-5 p-12 mx-auto bg-gray-100 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4"
      >
        <div
          class="flex items-center justify-center"
          v-for="item in items"
          :key="item.id"
        >
          <a :href="item.url" target="_blank">
            <img class="object-cover object-center h-28 w-36" :alt="item.url" :src="getBaseUrl() + item.Image.url" />
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Affiliate",
  data() {
    return {
      items: [],
      loading: true,
      error: null,
    };
  },
  methods: {
    getBaseUrl(){
      return baseUrl;
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/affiliates`);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped></style>
