<template>
  <div>
    <nav
      class="container px-4 py-2 mx-auto font-sans text-gray-600 bg-white md:py-4 max-w-7xl"
    >
      <div class="mx-auto md:flex md:flex-wrap md:items-center">
        <div class="flex items-center justify-between">
          <router-link to="/">
            <div class="flex items-center">
              <img
                src="@/assets/logo.png"
                alt="green globe Logo"
                class="h-28"
              />
            </div>
          </router-link>
          <button
            @click="toggle"
            class="px-3 py-1 text-gray-600 border border-gray-600 border-solid rounded opacity-50 hover:opacity-75 md:hidden"
            id="navbar-toggle"
          >
            <svg
              fill="none"
              stroke="currentColor"
              class="w-6 h-6"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div
          :class="open ? 'flex' : 'hidden'"
          class="flex-col mt-3 md:flex md:flex-row md:ml-auto md:mt-0"
          id="navbar-collapse"
        >
          <router-link
            v-for="nav in navs"
            :key="nav.name"
            @click.native="scrollToTop"
            :to="nav.route"
            class="p-2 font-sans text-sm text-gray-700 uppercase transition-colors duration-300 lg:px-3 md:mx-2 hover:text-primary-green"
          >
            {{ nav.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      navs: [
        {
          name: "Home",
          route: "/",
        },
        {
          name: "Products",
          route: "/products",
        },
        {
          name: "Catalog",
          route: "/catalogs",
        },
        {
          name: "News",
          route: "/news",
        },

        {
          name: "Contact us",
          route: "/contact-us",
        },
        {
          name: "About us",
          route: "/about-us",
        },
      ],
      open: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
