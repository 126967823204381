<template>
  <div
    class="flex flex-col pb-10 mx-auto mb-10 font-sans border-b border-gray-200 sm:flex-row"
  >
    <div
      class="inline-flex justify-center flex-shrink-0 sm:w-48 sm:h-48 sm:mr-8"
    >
      <img
        class="object-cover object-center w-full h-full rounded-sm"
        :src="baseUrl + news.newsImage.url"
        :alt="news.newsTitle"
      />
    </div>
    <div class="flex-grow mt-6 text-left sm:mt-0">
      <div class="mb-4">
        <h2 class="text-lg font-semibold text-gray-900 ">
        {{ news.newsTitle }}
      </h2>
      <span class="text-sm text-gray-500">{{ news.publishedDate }}</span>
      </div>
      <p
        class="mb-3 font-normal text-gray-500"
        :class="{ 'line-clamp-5': showMore }"
      >
        <VueShowdown :markdown="news.newsContent" />
      </p>
      <a
        @click="showMore = !showMore"
        class="inline-flex items-center transition-colors duration-300 border-b border-transparent border-solid cursor-pointer hover:text-blue-600 text-primary-blue hover:border-primary-blue "
      >
        <span v-if="showMore">Read More ...</span>
        <span v-else>... Less</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleNewsDisplay",
  props: ["news","baseUrl"],
  data() {
    return {
      showMore: true,
    };
  },
};
</script>

<style></style>
