<template>
  <div
    v-if="show"
    class="fixed top-0 left-0 flex items-center justify-center w-full h-full  modal"
  >
    <div
      @click.self="close_modal()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg  modal-container md:max-w-md"
    >
      <div class="px-8 py-8 text-left modal-content">
        <div class="flex justify-end">
          <svg
            @click="close_modal()"
            class="w-8 h-8 text-gray-500 cursor-pointer hover:text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div class="flex items-center mb-3">
          <p class="text-2xl font-bold">Download Catalog</p>
        </div>
        <p class="mb-4 text-sm font-normal text-gray-600">
          Fill the form below to download the document
        </p>
        <ValidationObserver ref="form">
          <form @submit.prevent="downloadFile({ url, label })" class="mb-10">
            <div class="relative mb-4">
              <label
                for="fullName"
                class="block text-sm font-medium text-gray-700 required"
              >
                Full Name
              </label>
              <ValidationProvider
                name="Name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  id="fullname"
                  v-model="fullName"
                  name="Full Name"
                  placeholder="Full name"
                  class="block w-full px-3 py-1 mt-1 text-sm leading-8 text-gray-600 transition-colors duration-200 ease-in-out bg-gray-200 border rounded-sm outline-none  focus:bg-white focus:border-gray-600 focus:ring-1 focus:ring-gray-600"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <div class="relative">
                <label
                  for="phone"
                  class="block text-sm font-medium text-gray-700 required"
                >
                  Phone Number
                </label>
                <ValidationProvider
                  name="Phone number"
                  :rules="{
                    required: true,
                    regex: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                  }"
                  v-slot="{ errors }"
                >
                  <input
                    type="tel"
                    id="phone"
                    v-model="phone"
                    name="phone"
                    placeholder="Phone number"
                    class="block w-full px-3 py-1 mt-1 text-sm leading-8 text-gray-600 transition-colors duration-200 ease-in-out bg-gray-200 border rounded-sm outline-none  focus:border-gray-600 focus:bg-white focus:ring-1 focus:ring-gray-600"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="relative mb-6">
              <label
                for="email"
                class="block text-sm font-medium text-gray-700 required"
              >
                Email
              </label>
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  name="email"
                  placeholder="Email"
                  class="block w-full px-3 py-1 mt-1 text-sm leading-8 text-gray-600 transition-colors duration-200 ease-in-out bg-gray-200 border rounded-sm outline-none  focus:border-gray-600 focus:bg-white focus:ring-1 focus:ring-gray-600"
                />
                <span class="text-sm text-red-600">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="relative mb-4">
              <label
                for="title"
                class="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                v-model="title"
                name="title"
                placeholder="Title"
                class="block w-full px-3 py-1 mt-1 text-sm leading-8 text-gray-600 transition-colors duration-200 ease-in-out bg-gray-200 border rounded-sm outline-none  focus:bg-white focus:border-gray-600 focus:ring-1 focus:ring-gray-600"
              />
            </div>
            <div class="relative mb-4">
              <label for="name" class="block text-sm font-medium text-gray-700">
                Company Name
              </label>
              <input
                type="text"
                id="company"
                v-model="company"
                name="company"
                placeholder="Company name"
                class="block w-full px-3 py-1 mt-1 text-sm leading-8 text-gray-600 transition-colors duration-200 ease-in-out bg-gray-200 border rounded-sm outline-none  focus:bg-white focus:border-gray-600 focus:ring-1 focus:ring-gray-600"
              />
            </div>
            <button
              loading="true"
              type="submit"
              value="download"
              class="w-full py-3 mt-3 text-white transition-colors duration-200 ease-in-out border-0 rounded-sm  bg-primary-blue focus:outline-none hover:bg-blue-800"
            >
              <div v-if="!loading">Download</div>
              <div v-else class="flex items-center justify-center">
                <svg
                  class="w-5 h-5 mr-3 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
                Processing
              </div>
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import axios from "axios";
import { baseUrl } from "../../Api";

export default {
  name: "DownloadFile",

  props: {
    show: Boolean,
    url: String,
    label: String,
  },
  data() {
    return {
      fullName: "",
      phone: "",
      email: "",
      title: "",
      company: "",
      snackbar: false,
      snackbar_message: "",
      error: false,
      loading: false,
      loadingImage: require("../../assets/curve-Loading.gif"),
    };
  },

  methods: {
    close_modal() {
      this.$emit("close");
    },
    async downloadFile({ url, label }) {
      const success = await this.$refs.form.validate();
      if (success) {
        this.loading = true;
        try {
          axios
            .post(`${baseUrl}/downloads`, {
              fullName: this.fullName,
              Phone: this.phone,
              Email: this.email,
              Title: this.title,
              Company: this.company,
              fileName: label,
            })
            .then(function (response) {
              this.error = response;
            })
            .catch(function (error) {
              console.log(error);
            });
          axios
            .get(url, { responseType: "blob" })
            .then((response) => {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = label;
              link.click();
              URL.revokeObjectURL(link.href);
              this.loading = false;
            })
            .catch(console.error);
        } catch (error) {
          console.log("Error");
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
.required::after {
  content: "*";
  color: #ff0000;
}
</style>
