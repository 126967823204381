<template>
  <div>
    <section
      class="container px-8 py-24 mx-auto font-sans text-gray-600 max-w-7xl"
    >
      <div v-if="!loading">
        <h1
          class="mb-20 text-4xl font-bold text-center text-gray-900  md:text-5xl md:leading-tight md:font-extrabold"
        >
          Catalogs
        </h1>
        <div class="flex flex-wrap -m-4">
          <!-- class="p-4 xl:w-1/3 md:w-1/2" -->
          <div v-for="catalog in items" :key="catalog.id">
            <div class="p-6 bg-gray-100 rounded-sm">
              <img
                class="object-cover object-center w-full mb-6 rounded-sm h-72"
                :src="getBaseUrl() + catalog.Cover.url"
                :alt="catalog.Title"
              />

              <h5 class="mb-4 text-2xl font-bold tracking-tight text-gray-900">
                {{ catalog.Title }}
              </h5>
              <p class="mb-4 text-base leading-relaxed">
                <VueShowdown :markdown="catalog.Content" />
              </p>
              <div class="flex items-center justify-end">
                <button
                  @click="showModal = true"
                  class="inline-flex items-center justify-end px-3 py-2 text-sm font-medium text-center text-white rounded cursor-pointer  bg-primary-green focus:ring-1 focus:ring-primary-blue hover:bg-opacity-80"
                >
                  Download
                  <svg
                    class="w-5 h-5 ml-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <DownloadFile
                :show="showModal"
                @close="showModal = false"
                :url="getBaseUrl() + catalog.File.url"
                :label="catalog.Title"
              >
              </DownloadFile>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="loadingImage" class="w-64 m-auto" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import DownloadFile from "../components/Modal/DownloadFile";

export default {
  name: "Catalog",
  components: { DownloadFile },
  data: () => ({
    items: [],
    loadingImage: require("../assets/curve-Loading.gif"),
    loading: true,
    showModal: false,
  }),
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/catalogs`);
      // console.log("catalog", response);
      this.items = response.data;
      this.loading = false;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
