<template>
  <div>
    <section class="px-8 mx-auto my-24 font-sans text-gray-600 max-w-7xl">
      <div
        class="grid items-center grid-cols-1 mb-24 md:grid-cols-2 gap-y-10 md:gap-y-32 gap-x-10 " :class="index % 2 === 0 ? 'bg-green-50' : 'bg-gray-100'"
        v-for="(section, index) in sections"
        :key="section.id"
      >
        <div
          class="order-1"
          :class="index % 2 === 0 ? 'md:order-1 p-16' : 'md:order-2 p-16'"
        >
          <div class="flex flex-col justify-between">
            <h1
              class="mb-3 text-4xl font-semibold tracking-tight text-center text-primary-green md:leading-tight sm:text-left md:text-4xl"
            >
              {{ section.Title }}
            </h1>
            <div class="w-24 h-1 mb-6 text-center bg-primary-blue"></div>
          </div>
          <p
            class="mb-5 text-lg font-light text-center text-gray-800 sm:text-left sm:rtl:text-right md:text-md"
          >
            <VueShowdown :markdown="section.Content" />
          </p>
        </div>
        <div
          class="flex items-center justify-center w-full h-full"
          :class="index % 2 === 0 ? 'order-2' : 'order-1'">
          <img :alt="section.Title" :src="getBaseUrl()+section.Images.url">
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Sections",
  data() {
    return {
      sections: [],
    };
  },
  methods: {
    getBaseUrl(){
      return baseUrl
    }
  },
  async mounted() {
    try {
      const response = await axios.get(`${baseUrl}/sections`);
      this.sections = response.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style></style>
